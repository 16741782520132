import { unionize, ofType, UnionOf } from "unionize"
import * as peerRegistry from "./peer-registry"
import { Dispatcher } from "./actions/index"
import * as projectDataCoordinator from "./project-data-coordinator"
import { timeout } from "./utils/promise"
import { vm } from "./vm"
import type {
    ProjectUpdateNotification,
    ReconnectSendChannel,
    ReplaceMessagesBroadcast,
    SendMessageToCreator,
    SetSpaceVarMessage,
    SnapshotUpdateNotification,
} from "@cocoplatform/coco-rtc-shared"
import { spaceVarsEmitter } from "./space-vars"
import { ConType, DataChannelType } from "./peer"
import { isBlocksSpace } from "./space"
import { sendWSMessage } from "./socket-client"
import { Evt } from "evt"

export { ProjectUpdateNotification }

export interface StreamStatusUpdateNotification {
    id: string
    enabled: boolean
}

export interface ProjectPullRequest {
    id: string
    requestId: string
}

export interface ProjectPullResponse {
    requestId: string
    projectId: string
    version: string
    chunk: string // Base64 encoded array buffer slice
    isComplete: boolean
    sizeBytes?: number
}

export interface MessageToCreatorNotification {
    id: string
    data: any
}

export interface NewMessageAddRequest {
    id: string
    data: string
}

export interface ReplaceMessagesRequest {
    id: string
    data: string[]
}

export interface WaveBroadcastNotification {
    id: string
    sourcePersonaId?: string
    sourceRemixPersonaId?: string
}

const DataChannelMessageVariants = {
    cocoWaveBroadcast: ofType<WaveBroadcastNotification>(),
    cocoStreamStatusUpdate: ofType<StreamStatusUpdateNotification>(),
    cocoProjectUpdate: ofType<ProjectUpdateNotification>(),
    cocoProjectPullRequest: ofType<ProjectPullRequest>(),
    cocoProjectPullResponse: ofType<ProjectPullResponse>(),
    cocoMessageToCreator: ofType<MessageToCreatorNotification>(),
    cocoAddNewMessage: ofType<NewMessageAddRequest>(),
    cocoReplaceMessages: ofType<ReplaceMessagesRequest>(),
    cocoSetSpaceVar: ofType<Omit<SetSpaceVarMessage, "type">>(),
    cocoSnapshotUpdate: ofType<SnapshotUpdateNotification>(),
} as const

export const DataChannelMessages = unionize(DataChannelMessageVariants, {
    tag: "type" as const,
})

export type DataChannelMessage = UnionOf<typeof DataChannelMessages>

export type DataChannelMessageType = keyof typeof DataChannelMessageVariants

interface SendParams {
    excludeSelf?: boolean
    retryOnFailure?: boolean
    maxAttempts?: number
    awaitOpen?: boolean
    ensureSeq?: boolean
}


// if a new collaborator comes in, send them my existing messages
export const sendMyMsgsToNewCollaborator = async (id: string) => {
    if (!vm?.runtime?.coco) return
    const messages = vm.runtime.coco.messages.get(peerRegistry.currentPeerId)
    if (!messages) return
    await sendWSMessage<ReplaceMessagesBroadcast>({
        type: "replace-messages",
        id: peerRegistry.ensureCurrentPeerId(),
        data: messages,
        targetId: id,
    });
}

/* const onError = (
    event: Event,
    collaboratorId: string,
    peerConnection: RTCPeerConnection
) => {
    console.error("Data channel error received", event)
    scheduleHandlingBrokenConnection(collaboratorId, peerConnection)
} */

let pendingSnapshotMap = new Map<
    /* peerId */ string,
    Map</* snapshotId */ string, string[]>
>()

const onSnapshotUpdate = async (message: SnapshotUpdateNotification) => {
    if (message.chunkCount == 1) {
        projectDataCoordinator.collaboratorIdToSnapshotMap.set(
            message.id,
            message.snapshot
        )
        return
    }
    const chunkMap =
        pendingSnapshotMap.get(message.id) ?? new Map<string, string[]>()
    const chunks = chunkMap.get(message.snapshotId) ?? []
    chunks[message.chunkIdx] = message.snapshot
    if (
        // Last chunk received
        chunks[message.chunkCount - 1] &&
        // No holes
        !chunks.find((it) => !it)
    ) {
        projectDataCoordinator.collaboratorIdToSnapshotMap.set(
            message.id,
            chunks.join("")
        )
        pendingSnapshotMap.delete(message.id)
    } else {
        // More chunks needed
        pendingSnapshotMap.set(message.id, chunkMap)
        chunkMap.set(message.snapshotId, chunks)
    }
}



export const signalEmitter = new Evt();

export const onMessageToCreator = async (message: SendMessageToCreator) => {
    const currentpeer = peerRegistry.getCurrentPeer()
    const remixedProjectId = currentpeer?.remixedFrom;
    const remixedPeer = Array.from(peerRegistry.getAllPeers()).find((peer) => peer.projectId === remixedProjectId);

    
    if (
        message.targetId !== 'all creators' &&
        message.targetId !== currentpeer?.personaId &&
        message.targetId !== remixedPeer?.personaId
    ) {
        // drop message as not targetted to peer
        return;
    }

    if (!vm || !vm.runtime) {
        // emit signal for p5js use-cases
        signalEmitter.post({
            ...message,
            type: "sendMessageToCreator",
        });
        return;
    }

    if (message.sourcePersonaId) {
        vm.runtime.startHats("coco_whenReceiveMessageFromCreator", {
            MESSAGE: message.data,
            CREATOR: message.sourcePersonaId,
        })
    }

    if (message.sourceRemixPersonaId) {
        vm.runtime.startHats("coco_whenReceiveMessageFromCreator", {
            MESSAGE: message.data,
            CREATOR: message.sourceRemixPersonaId,
        })
    }

    vm.runtime.startHats("coco_whenReceiveMessageFromCreator", {
        MESSAGE: message.data,
        CREATOR: "any creator",
    })

}

export const onSetSpaceVar = async (message: Omit<SetSpaceVarMessage, "type">) => {
    spaceVarsEmitter.post({
        ...message,
        type: "set-space-var",
    })
}

export const onWaveBroadcast = async (message: WaveBroadcastNotification) => {
    if (message.id !== peerRegistry.currentPeerId) {
        if (message.sourcePersonaId) {
            vm.runtime.startHats("cocoWave_waveToCreator", {
                USER: message.sourcePersonaId,
            })
        }

        if (message.sourceRemixPersonaId) {
            vm.runtime.startHats("cocoWave_waveToCreator", {
                USER: message.sourceRemixPersonaId,
            })
        }

        vm.runtime.startHats("cocoWave_waveToCreator", {
            USER: "any creator",
        })
    }
}

export const onAddNewMessage = async (message: NewMessageAddRequest) => {
    if (!vm) {
        console.warn("Not receiving new message because vm unavailable")
        return
    }
    vm.runtime.emit("ADD_NEW_COCO_MESSAGE", message.data, message.id)
}

export const onReplaceMessages = async (message: ReplaceMessagesRequest) => {
    if (!vm) {
        console.warn("Not receiving new message because vm unavailable")
        return
    }
    vm.runtime.emit("REPLACE_COCO_MESSAGES", message.data, message.id)
}
