import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Penguins from '../server-down/Penguins.png';
import { Placement, Step } from 'react-joyride';
import { useTutorial } from './tutorial-atom';
import C from './getting-started.css';
import { useWindowSize } from 'rooks';
import PenguinsGraphic from './penguin-pair.svg';
import { useUserProfile } from 'atoms/user-profile';
import { Trans } from '@lingui/macro';
import { LaunchOutlined } from '@mui/icons-material';
export const GETTING_STARTED_TUTORIAL = 'Getting Started';
export const ADD_MEMBERS_TUTORIAL = 'Add Members';

function CircularListNumber(p: { children: number }) {
  return (
    <Box
      sx={{
        width: '2rem',
        height: '2rem',
        borderRadius: '50%',
        backgroundColor: 'var(--color-selected)',
        color: 'black',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        marginRight: '0.5rem',
      }}
    >
      {p.children}
    </Box>
  );
}

function GettingStartedStep(p: {
  canManageUsers: boolean;
  isEducationalUser: boolean;
  isEducationalCorridor?: boolean;
}) {
  const { innerWidth } = useWindowSize();
  const isMobile = (innerWidth || 0) < 600;

  const tutorial = useTutorial();

  const { userProfile } = useUserProfile();

  const isEduAdminAndEduCorridor =
    p.isEducationalUser && p.isEducationalCorridor && p.canManageUsers;

  return (
    <Stack className={C.GettingStartedSteps}>
      <Box
        px={8}
        pt={2}
        sx={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'var(--color-coco-yellow)',
          overflow: 'hidden',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <img
          src={PenguinsGraphic}
          alt='Penguins'
          style={{
            width: '100%',
            height: '100px',
            margin: '0 auto',
            transform: 'translateY(10%)',
          }}
        />
      </Box>

      <Stack
        sx={{
          width: '100%',
        }}
        px={4}
        py={1}
      >
        <Typography mb={4} textAlign='center' mt={2} variant='h2'>
          <Trans>
            Welcome to CoCo,{' '}
            {userProfile?.firstName ||
              userProfile?.username ||
              userProfile?.lastName}
            !
          </Trans>
        </Typography>
        {p.isEducationalUser && p.canManageUsers ? (
          <Stack gap={3}>
            <Stack gap={1.8}>
              <Typography variant='h4'>
                <CircularListNumber>{1}</CircularListNumber>
                <Trans>Schedule an introductory session</Trans>
              </Typography>
              <Stack
                pt={2}
                pb={1}
                sx={{
                  backgroundColor: '#ed7985',
                  borderRadius: '0.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                alignItems='center'
                gap={0.5}
                onClick={() => {
                  window.open(
                    'https://calendly.com/cocobuild/getting-started-webinar',
                  );
                }}
              >
                <Box
                  px={1.5}
                  py={0.5}
                  sx={{
                    position: 'absolute',
                    top: '-15px',
                    right: '10px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    color: 'white',
                    backgroundColor: '#b7626d',
                    borderRadius: '0.8rem',
                  }}
                >
                  <Trans>Recommended</Trans>
                </Box>
                <Typography
                  sx={{
                    verticalAlign: 'middle',
                  }}
                  color='white'
                  variant='h4'
                >
                  <Trans>Join a webinar by MIT team</Trans>{' '}
                  <LaunchOutlined
                    sx={{
                      verticalAlign: 'middle',
                    }}
                  />
                </Typography>
                <Typography color='white' variant='body2'>
                  <Trans>40-minute session</Trans>
                </Typography>
              </Stack>
            </Stack>
            {isEduAdminAndEduCorridor && (
              <Stack gap={1.8}>
                <Typography variant='h4'>
                  <CircularListNumber>{2}</CircularListNumber>
                  <Trans>Join the</Trans>{' '}
                  <a
                    href='https://www.facebook.com/groups/cocobuild'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={C.FacebookLink}
                  >
                    <Trans>CoCo Educators</Trans>
                  </a>{' '}
                  <Trans>group on Facebook</Trans>
                </Typography>
              </Stack>
            )}
            <Stack gap={1.8}>
              <Typography variant='h4'>
                <CircularListNumber>
                  {isEduAdminAndEduCorridor ? 3 : 2}
                </CircularListNumber>
                <Trans>Explore your dashboard in a few quick steps</Trans>
              </Typography>
              <Stack
                p={2}
                sx={{
                  backgroundColor: 'var(--color-selected)',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                }}
                alignItems='center'
                gap={1.5}
                onClick={() => {
                  tutorial.setStepIndex(1);
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--color-primary)',
                  }}
                  variant='h4'
                >
                  <Trans>Start Tour</Trans>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack gap={3}>
            <Stack gap={1.8}>
              <Typography textAlign='center' variant='h4' fontWeight='normal'>
                <Trans>Explore your dashboard in a few quick steps</Trans>
              </Typography>
              <Stack
                p={2}
                sx={{
                  backgroundColor: 'var(--color-selected)',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                }}
                alignItems='center'
                gap={1.5}
                onClick={() => {
                  tutorial.setStepIndex(1);
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--color-primary)',
                  }}
                  variant='h4'
                >
                  <Trans>Start Tour</Trans>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        {/* 
        <Typography
          mt={3}
          textAlign='center'
          variant='h4'
          fontWeight='regular'
          sx={{
            color: 'var(--color-subtext)',
            cursor: 'pointer',
          }}
          onClick={() => {
            tutorial.stopTutorial();
          }}
        >
          <Trans>Skip for now</Trans>
        </Typography> */}
      </Stack>
      {isMobile && (
        <>
          <br />
          <Typography px={4} textAlign='center' color='red' variant='caption'>
            <Trans>
              CoCo is not optimized for mobile devices. Please use a desktop or
              laptop for the best experience.
            </Trans>
          </Typography>
        </>
      )}
    </Stack>
  );
}

const GoToYourCommunityStep = ({
  targetCommunityIcon,
}: {
  targetCommunityIcon: string;
}) => {
  useEffect(() => {
    if (targetCommunityIcon) {
      const target = document.querySelector(targetCommunityIcon);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);
  return (
    <Stack className={C.GettingStartedSteps}>
      <Typography variant='h2'>
        <Trans>Go to your community</Trans>
      </Typography>
      <Typography variant='body1'>
        <Trans>Click this icon to return to your community.</Trans>
      </Typography>
    </Stack>
  );
};

export const getGettingStartedSteps = (
  canManageUsers = true,
  isEducationalUser = false,
  communityId?: string,
  isEducationalCorridor = false,
): Step[] => {
  const isEduAdminAndEduCorridor =
    isEducationalUser && isEducationalCorridor && canManageUsers;

  const targetCommunityIcon =
    communityId && communityId != '$all'
      ? `.corridor-icon-${communityId}`
      : '.first-corridor-icon';
  return [
    {
      content: (
        <GettingStartedStep
          canManageUsers={canManageUsers}
          isEducationalUser={isEducationalUser}
          isEducationalCorridor={isEducationalCorridor}
        />
      ),
      placement: 'center',
      target: 'body',
      showSkipButton: false,
      showProgress: false,
      // disableBeacon: true,
      spotlightClicks: false,
      locale: {
        next: <Trans>Start</Trans>,
      },
      styles: {
        tooltip: {
          padding: 0,
          maxWidth: '90vw',
          width: '500px',
          overflow: 'auto',
          scrollbarColor: 'transparent',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          borderRadius: '0.5rem',
          border: '1px solid black',
        },
        tooltipContent: {
          padding: 0,
        },
        buttonSkip: {
          marginLeft: 15,
          color: 'var(--color-primary)',
        },
        buttonNext: {
          marginRight: 15,
          padding: '0.5rem 1rem',
          display: 'none',
        },
      },
    },
    ...(canManageUsers
      ? []
      : [
          {
            content: (
              <Stack className={C.GettingStartedSteps}>
                <Typography variant='h2'>
                  <Trans>Explore collaborative spaces in your community!</Trans>
                </Typography>
                <Typography variant='body1'>
                  <Trans>
                    This is a private community for invited members to
                    co-create, code, and collaborate together in CoCo spaces.
                    All the spaces are saved here.
                  </Trans>
                </Typography>
              </Stack>
            ),
            target: '.dashboard-body',
            disableBeacon: true,
            placement: 'top-end' as Placement,
          },
        ]),
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          {isEduAdminAndEduCorridor ? (
            <>
              <Typography variant='h2'>
                <Trans>
                  Students can build projects with peers in CoCo spaces
                </Trans>
              </Typography>
              <Typography variant='body1'>
                <ol>
                  <li>
                    <Trans>
                      During any classroom session, ask students to form groups
                      of 2-4.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Have one member in each group create a CoCo space and
                      invite others.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      The others will get a notification to join that space.
                    </Trans>
                  </li>
                </ol>
                <Trans>
                  Members can create new spaces anytime for working on new
                  projects together.
                </Trans>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h2'>
                <Trans>Build projects with friends in CoCo spaces</Trans>
              </Typography>
              <Typography variant='body1'>
                <ol>
                  <li>
                    <Trans>Form groups of 2-4 with your friends.</Trans>
                  </li>
                  <li>
                    <Trans>
                      One person in each group can create a CoCo space and
                      invite others.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      The others will get a notification to join that space.
                    </Trans>
                  </li>
                </ol>
                <Trans>
                  Create new spaces anytime for working on new projects
                  together.
                </Trans>
              </Typography>
            </>
          )}
        </Stack>
      ),
      target: '#coco-new-space-btn',
      disableBeacon: true,
      ...(!canManageUsers
        ? { hideFooter: true, spotlightClicks: true, hideCloseButton: false }
        : {}),
    },
    ...(canManageUsers ? getAddMembersSteps(isEducationalCorridor) : []),
  ];
};

export function getAddMembersSteps(isEducationalCorridor: boolean): Step[] {
  return [
    {
      content: (
        <Stack className={C.GettingStartedSteps}>
          <Typography variant='h2'>
            <Trans>Invite members and start co-creating!</Trans>
          </Typography>
          <Typography variant='body1'>
            {isEducationalCorridor ? (
              <>
                <Trans>Click to add members using any option:</Trans>
                <ol>
                  <li>
                    <Trans>Import from Google classroom</Trans>
                  </li>
                  <li>
                    <Trans>Send email invites</Trans>
                  </li>
                  <li>
                    <Trans>Upload a CSV to create student logins</Trans>
                  </li>
                </ol>
                <Trans>
                  Only the members you invite can access this community.
                </Trans>
              </>
            ) : (
              <Trans>
                Click to add members by sending email invites. Only the members
                you invite can access this community.
              </Trans>
            )}
          </Typography>
        </Stack>
      ),
      target: '#add-members-button',
      disableBeacon: true,
      spotlightClicks: true,
      hideFooter: true,
      hideCloseButton: false,
    },
  ];
}
