import React, { FormEvent, useRef, useState } from 'react';
import cc from 'classcat';
import C from './InviteJoinForm.css';
import HeartIcon from '../home-page/pink-heart.svg';
import {
  typeformBaseUrl,
  typeformId,
} from 'components/home-page/external-links';
import { createPopup as createTfPopup } from '@typeform/embed';
import { cocoSessionId } from 'utils/auto-session';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

if (typeof window !== 'undefined') {
  import(
    // @ts-expect-error Loader is present
    '!!style-loader!css-loader?modules=false!@typeform/embed/build/css/popup.css'
  );
}

export default function InviteJoinForm(p: {
  onClose?: () => void;
  email?: string;
  autofocus?: boolean;
}) {
  const { _ } = useLingui();
  const [email, setEmail] = useState(p.email || '');
  const emailInputRef = useRef<HTMLInputElement | null>(null);

  const track = useTrack();

  return (
    <>
      <form
        className={cc([C.outer, 'invite-join-form'])}
        onSubmit={(e) => handleSubmit(e, p.onClose)}
      >
        <div className={C.container}>
          <div className={C.cell}>
            <input
              required
              type='text'
              className={C.input}
              placeholder={t`Enter your email`}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              autoFocus={p.autofocus}
              ref={emailInputRef}
            />
          </div>
          <div className={C.cell}>
            <button
              type='submit'
              style={{
                color: 'var(--col-fg-default)',
              }}
              onClick={() => {
                track(EVENTS.JOIN_INVITE_LIST, INTERACTIONS.CLICK, {
                  email,
                });
              }}
            >
              <Trans>Join the invite list</Trans>
            </button>
            <img
              src={HeartIcon}
              className={C.heartIcon}
              alt={_(msg`heart icon`)}
            />
          </div>
        </div>
      </form>
    </>
  );

  async function handleSubmit(e: FormEvent, onClose?: () => void) {
    e.stopPropagation();
    e.preventDefault();
    if (!email) return;

    emailInputRef.current?.blur();

    httpClient
      .post(`/survey/email-invite-list/0/submission`, {
        email,
        cocoSessionId,
      })
      .catch((e) => {
        console.error(e);
      });

    setTimeout(() => {
      openTFPopup({ email, onClose });
    }, 100);
  }

  function openTFPopup(opts: { email: string; onClose?: () => void }) {
    try {
      const container = document.getElementById('modal-container');
      if (!container) throw new Error('#modal-container missing');
      container.classList.add('active');
      createTfPopup(typeformId, {
        hidden: {
          email: opts.email,
        },
        height: window.innerHeight - 80,
        width: window.innerWidth - 80,
        container,
        onClose: () => {
          container.classList.remove('active');
          opts.onClose?.();
        },
      }).toggle();
    } catch (e) {
      window.open(getExternalFormUrl(opts.email));
    }
  }

  function getExternalFormUrl(email: string) {
    return `${typeformBaseUrl}#email=${encodeURIComponent(email)}`;
  }
}
