const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const MODAL_CAPTURE_SCREEN = 'captureScreenModal';
const MODAL_IDLE = 'idleModal';
const MODAL_MEDIA_FAILURE = 'mediaFailureModal';
const MODAL_VIDEO_AUDIO_PERMISSION = 'videoAudioPermissionModal';

export const openModal = (modal: string, context?: any) => ({
    type: OPEN_MODAL,
    modal: modal,
    context
})

const openCaptureScreenModal = (initiator: 'RECORD_SPACE' | 'SHARE_SPACE') =>
    openModal(MODAL_CAPTURE_SCREEN, { initiator })

const openIdleModal = () =>
    openModal(MODAL_IDLE)

const openMediaFailureModal = () =>
    openModal(MODAL_MEDIA_FAILURE)

const openVideoAudioPermissionModal = (deniedDeviceType: "VIDEO" | "AUDIO", reason?: string) =>
    openModal(MODAL_VIDEO_AUDIO_PERMISSION, { deniedDeviceType , reason })

export const modalActionCreators = {
    openCaptureScreenModal,
    openIdleModal,
    openMediaFailureModal,
    openVideoAudioPermissionModal,
}
