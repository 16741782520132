import { atom } from "nanostores"
import type { NodeWithChildren, Element } from "domhandler"
import {
    CodeEditorState,
    state,
    CodeFile,
    getDefaultHTML,
    AssetFile,
    CodeEditorFileType,
    CodeEditorFile,
    findFile,
} from "./state"
import debounce from "lodash/debounce"
import { ElementType } from "htmlparser2"

export interface ImportMapping {
    path: string
    target: string
}

export interface CodePreviewOptions {
    importMappings: ImportMapping[]
}


export const objectUrlReplaceMap = new Map<string, string>()


export const createBlobMap = (files: CodeEditorFile[], map: { [originalUrl: string]: string }) => {
    files.forEach((file) => {
        if (file.type === CodeEditorFileType.Asset) {
            const url = URL.createObjectURL(file.content)
            map[file.path] = url
        } else if (file.type === CodeEditorFileType.Folder) {
            createBlobMap(file.children, map)
        }
    });

    return map;
}

export const getCodePreviewStore = async (
    opts: CodePreviewOptions,
    editorState = state
) => {
    const imported = await import("../utils/dom-transformer")
    const { parseDocument, serialize, Text, Element } = imported
    const processChildren = (
        dom: NodeWithChildren,
        files: (CodeEditorFile)[],
        blobs: Map<string, string> = new Map()
    ) => {
        let children: typeof dom.children = []
        for (let i = 0; i < dom.children.length; i++) {
            let el = dom.children[i]
            if (!(el instanceof Element)) {
                children.push(el)
                continue
            }
            let shouldIgnore = false
            if (el.tagName.toLowerCase() === "script") {
                const src = el.attribs.src
                const isLocal =
                    src?.match(/\.js$/) && !src?.match(/^https?:\/\//)
                if (src && isLocal) {
                    // Local script
                    const assetPath = normalizeAssetPath(src)
                    const file = findFile(assetPath, files)
                    if (file?.isText) {
                        // Reference to file in project
                        delete el.attribs.src

                        const existingUrl = objectUrlReplaceMap.get(assetPath);
                        if (existingUrl) {
                            // Reuse the existing object URL
                            el.attribs.src = existingUrl
                            continue
                        }

                        // Create object URL for the script
                        const blob = new Blob([file.content], {
                            type: "application/javascript",
                        })
                        const url = URL.createObjectURL(blob)
                        el.attribs.src = url

                        blobs.set(assetPath, url)

                        // Cleanup the URL when the script is removed
                    } else {
                        const importMapping = opts.importMappings.find(
                            (it) => it.path === assetPath
                        )
                        if (importMapping) {
                            el.attribs.src = importMapping.target
                        } else {
                            console.warn(
                                "Skipping spurious asset reference: ",
                                src
                            )
                            shouldIgnore = true
                        }
                    }
                }
            } else if (el.tagName.toLowerCase() === "link") {
                const href = el.attribs.href
                if (href?.match(/\.css$/) && !href.match(/^https?:\/\//)) {
                    // Local style link
                    const assetPath = normalizeAssetPath(href)
                    const file = findFile(assetPath, files)
                    if (file?.isText) {
                        // Reference to file in project
                        delete el.attribs.href

                        const existingUrl = objectUrlReplaceMap.get(assetPath);
                        if (existingUrl) {
                            // Reuse the existing object URL
                            el.attribs.href = existingUrl
                            continue
                        }

                        // Create object URL for the style
                        const blob = new Blob([file.content], {
                            type: "text/css",
                        })
                        const url = URL.createObjectURL(blob)
                        el.attribs.href = url

                        blobs.set(assetPath, url)
                    } else {
                        const importMapping = opts.importMappings.find(
                            (it) => it.path === assetPath
                        )
                        if (importMapping) {
                            el.attribs.href = importMapping.target
                        } else {
                            console.warn(
                                "Skipping spurious asset reference: ",
                                href
                            )
                            shouldIgnore = true
                        }
                    }
                }
            } else if (el.children) {
                processChildren(el, files, blobs)
            }
            if (!shouldIgnore) {
                children.push(el)
            }
        }
        dom.children = children

        return blobs
    }

    const locateElementByTagName = (
        dom: NodeWithChildren,
        tagName: string
    ) => {
        // recursively search for the first element with the given tag name
        const search = (node: NodeWithChildren): Element | undefined => {
            if (node instanceof Element && node.tagName.toLowerCase() === tagName) {
                return node
            }
            if (node.children) {
                for (const child of node.children) {
                    const found = search(child as NodeWithChildren)
                    if (found) {
                        return found
                    }
                }
            }
            return undefined
        }

        return search(dom)
    }

    const insertCoCoPreludeScript = (dom: NodeWithChildren) => {
        const preludeScript = new Element("script", {
            src: "coco-prelude.js",
        })

        const container = locateElementByTagName(dom, "head") ?? locateElementByTagName(dom, "body")
        if (container) {
            container.children.unshift(preludeScript)
        }
    }

    const insertBlobMapScript = (dom: NodeWithChildren, blobMap: {[originalUrl: string]: string}) => {
        const preludeScript = `<script>
        var BLOB_ASSET_MAP = JSON.parse('${JSON.stringify(blobMap)}');
        </script>`;

        const container = locateElementByTagName(dom, "head") ?? locateElementByTagName(dom, "body")
        if (container) {
            container.children.unshift(parseDocument(preludeScript).children[0])
        }
    }

    const store = atom<string>("")

    const updatePreview = (es: CodeEditorState | null) => {
        if (!es) return
        const { files } = es
        const htmlFile: CodeFile | undefined = files.find(
            (it): it is CodeFile => it.path === "index.html" && it.isText
        )

        const html = htmlFile?.content ?? getDefaultHTML()
        const dom = parseDocument(html)
        insertCoCoPreludeScript(dom)

        // build map of blob urls for image assets
        const blobMap: {[originalUrl: string]: string} = {}
        createBlobMap(files, blobMap);

        insertBlobMapScript(dom, blobMap)

        // Rewrite asset paths
        const blobs = processChildren(dom, files);

        objectUrlReplaceMap.clear();

        blobs.forEach((url, path) => {
            objectUrlReplaceMap.set(url, path)
            objectUrlReplaceMap.set(url.split("/").pop()!, path)
        });

        
        store.set(serialize(dom))
    }

    const updatePreviewDebounced = debounce(updatePreview, 500)

    const unsubscribe = editorState.subscribe(updatePreviewDebounced)

    return { store, unsubscribe }
}

const normalizeAssetPath = (relPath: string) => {
    if (relPath?.startsWith("./")) {
        return relPath.slice(2)
    }
    return relPath
}
