import MenuIcon from '@mui/icons-material/Menu';
import { useCommunitySummaries } from 'atoms/community-summaries';
import cc from 'classcat';
import snakeCase from 'lodash/snakeCase';
import React, {
  CSSProperties,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'constants/routes';
import C from './MinSidebarCommunityList.css';
import { useParams } from 'react-router-dom';
import { Community } from '@cocoplatform/coco-rtc-shared';
import { useAuthState } from 'utils/auth-state';
import { shouldUseNewWindow, visitUrl } from 'utils/url';
import { useRecoilState } from 'recoil';
import { spaceFiltersAtom, defaultFilters } from 'atoms/space-filters';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { cssUrl } from 'utils/css';
import { useUserProfile } from 'atoms/user-profile';
import {
  Badge,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModalDialog from 'components/modal-dialog/ModalDialog';
import { useForm } from 'react-hook-form';
import ControlledTextField from 'components/forms/ControlledTextField';
import { notificationsEvt } from 'components/notification-menu/NotificationTrigger';
import { useEvt } from 'evt/hooks';
import { NOTIFICATION_EVENTS } from '@cocoplatform/coco-rtc-shared';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import worldCorridorUrl from 'assets/images/icon--world-corridor.png';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FeedbackButton from 'components/feedback-button/FeedbackButton';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import { GETTING_STARTED_TUTORIAL } from 'components/onboarding-tutorials/getting-started';
import { useFlags } from 'flagsmith/react';
import HelpIcon from '!!react-svg-loader!./icon--help.svg';
import { useModal } from 'mui-modal-provider';
import FeedbackModal from 'components/feedback-modal/feedback-modal';
import BugReportModal from 'components/bug-report-modal/bug-report-modal';
import Help from '@mui/icons-material/Help';
import { BugReport, RateReview, Error, Sms } from '@mui/icons-material';

interface MinSidebarCommunityListProps {
  showSidebarExpander?: boolean;
  onSidebarToggle?: () => void;
  activeCommunityId?: string;
}

export function MinSidebarCommunityList(p: MinSidebarCommunityListProps) {
  const communityIdParam = useParams<{ communityId: string }>()?.communityId;

  const communityId = p.activeCommunityId || communityIdParam;
  const { communities, trigger, syncState } = useCommunitySummaries();

  useEvt((ctx) => {
    notificationsEvt.attach(ctx, ({ eventName, content }) => {
      if (eventName === NOTIFICATION_EVENTS.COMMUNITY_MEMBERSHIP_REQ_APPROVED) {
        trigger();
      }
    });
  }, []);
  return (
    <MinSidebarCommunityListInner
      {...{ activeCommunityId: communityId, communities, ...p }}
    />
  );
}

interface MinSidebarCommunityListInnerProps
  extends MinSidebarCommunityListProps {
  activeCommunityId?: string;
  communities: (Community & {
    currentUserRoles: string[];
    genericInvitationCode: string;
  })[];
}

export function CreateCommunityButton() {
  const history = useHistory();
  const { _ } = useLingui();

  return (
    <li
      style={{
        position: 'sticky',
      }}
      key='$new'
      className={cc([C.item, C.borderless])}
      onClick={() => {
        history.push(ROUTES.COMMUNITY_SETUP_INIT);
      }}
    >
      <LightTooltip arrow placement='right' title={_(msg`Start new community`)}>
        <AddIcon style={{ color: 'var(--color-primary)' }} />
      </LightTooltip>
    </li>
  );
}

export function MinSidebarCommunityListInner(
  p: MinSidebarCommunityListInnerProps,
) {
  const { _ } = useLingui();
  const { isSignedIn, profileCompletionState } = useAuthState();
  const history = useHistory();
  const [, setSpaceFilters] = useRecoilState(spaceFiltersAtom);
  const { canCreateCommunity } = useUserProfile();
  const [joinModalVisible, setJoinModalVisible] = useState(false);

  const { world_corridor_page } = useFlags(['world_corridor_page']);

  const { control, handleSubmit, getValues } = useForm();

  const [anchor, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchor);

  const tutorial = useTutorial();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { startGettingStartedTutorial } = useTutorial();

  useEffect(() => {
    // scroll to active community
    const active = document.querySelector(`.${C.active}`);
    if (active) {
      active.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [p.activeCommunityId]);

  const { showModal } = useModal();

  return (
    <div className={C.container}>
      {joinModalVisible && (
        <ModalDialog
          isCloseable
          onClose={() => {
            setJoinModalVisible(false);
          }}
        >
          <Stack
            component='form'
            onSubmit={handleSubmit(() => {
              setJoinModalVisible(false);
              history.push(`/app/join/${getValues('code')}`);
            })}
          >
            <Typography textAlign='center' variant='h3' mb={4}>
              <Trans>Have an invite code to a community?</Trans>
            </Typography>
            <ControlledTextField
              name='code'
              id='code'
              placeholder={_(msg`Enter community code or link…`)}
              InputProps={{
                style: {
                  minWidth: '400px',
                },
              }}
              required
              rules={{
                required: true,
                pattern: /^[a-z ]+$/i,
              }}
              {...{ control }}
            />
            <Box mt={2} />
            <Button type='submit' variant='contained' color='primary'>
              <Trans>Join</Trans>
            </Button>
          </Stack>
        </ModalDialog>
      )}
      {isSignedIn &&
        (profileCompletionState === 'COMPLETE' ||
          profileCompletionState === 'PERMITTED_INCOMPLETE') && (
          <>
            {world_corridor_page?.enabled && (
              <>
                <ul className={C.list}>
                  {p.showSidebarExpander ? (
                    <li>
                      <a className={C.menuLink} onClick={p.onSidebarToggle}>
                        <MenuIcon />
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <CommunityIcon
                      name={_(msg`World Gallery`)}
                      className='world-corridor-icon'
                      abbreviate={false}
                      tooltipPlacement='right'
                      isActive={p.activeCommunityId === '$all'}
                      onClick={(e) => {
                        if (!isSignedIn) return;
                        setSpaceFilters(defaultFilters);
                        const useNewWindow = shouldUseNewWindow(e);
                        visitUrl('/app/dashboard', { history, useNewWindow });
                        if (
                          tutorial.name === GETTING_STARTED_TUTORIAL &&
                          tutorial.stepIndex == 2
                        ) {
                          tutorial.setStepIndex(
                            tutorial.stepIndex === 2 ? 3 : tutorial.stepIndex,
                          );
                        }
                      }}
                      avatarUrl={worldCorridorUrl}
                    />
                  </li>
                </ul>
                <Divider component='ul' className={C.divider} />
              </>
            )}
          </>
        )}
      <ul className={cc([C.list, C.corridors])}>
        {p.communities?.map((c, i) => {
          return (
            <li key={c.id}>
              <CommunityIcon
                name={c.name}
                tooltipPlacement='right'
                className={cc({
                  'first-corridor-icon': i === 0,
                  [`corridor-icon-${c.id}`]: true,
                })}
                id={c.id}
                key={c.id}
                avatarUrl={c.avatarUrl}
                isActive={c.id === p.activeCommunityId}
                invited={c.currentUserRoles?.length === 0 && c.invited}
                requested={c.currentUserRoles?.length === 0 && !c.invited}
                onClick={(e) => {
                  const useNewWindow = shouldUseNewWindow(e);
                  if (!isSignedIn) return;
                  if (
                    tutorial.name === GETTING_STARTED_TUTORIAL &&
                    tutorial.stepIndex === 4
                  ) {
                    tutorial.setStepIndex(
                      tutorial.stepIndex === 4 ? 5 : tutorial.stepIndex,
                    );
                  }
                  if (c.currentUserRoles?.length === 0) {
                    visitUrl(`/app/join/${c.genericInvitationCode}`, {
                      history,
                      useNewWindow,
                    });
                    return;
                  }
                  setSpaceFilters(defaultFilters);
                  visitUrl(`/app/dashboard/${c.id}`, { history, useNewWindow });
                }}
              />
            </li>
          );
        })}
        {p?.communities?.length <= 3 && (
          <ul className={C.list}>
            {canCreateCommunity && <CreateCommunityButton />}
          </ul>
        )}
      </ul>
      {p?.communities?.length > 3 && (
        <ul className={C.list}>
          {canCreateCommunity && <CreateCommunityButton />}
        </ul>
      )}
      <Divider component='ul' className={C.divider} />
      <ul className={C.list}>
        <li>
          {isSignedIn &&
            (profileCompletionState === 'COMPLETE' ||
              profileCompletionState === 'PERMITTED_INCOMPLETE') && (
              <>
                <IconButton
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  aria-label='help'
                  style={{
                    position: 'relative',
                  }}
                >
                  <HelpIcon
                    style={{
                      width: 36,
                      height: 36,
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchor}
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      startGettingStartedTutorial(p.activeCommunityId);
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Help />
                    </ListItemIcon>
                    <ListItemText>
                      <Trans>Getting Started Tour</Trans>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const modal = showModal(FeedbackModal, {
                        onClose: () => modal.destroy(),
                      });
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Sms />
                    </ListItemIcon>
                    <ListItemText>
                      <Trans>Share Feedback</Trans>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const modal = showModal(BugReportModal, {
                        onClose: () => modal.destroy(),
                      });
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Error />
                    </ListItemIcon>
                    <ListItemText>
                      <Trans>Report an Issue</Trans>
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
        </li>
      </ul>
    </div>
  );
}

const communityColors = [
  '#FFC100',
  '#88E0FE',
  '#FF9954',
  '#76E2B1',
  '#FAABF4',
  '#C2ADFF',
];

export const getCommunityColor = (id?: string) => {
  if (!id) return undefined;
  let sum = 0;
  for (let i = 0; i < id.length; i++) {
    sum += id.charCodeAt(i);
  }
  return communityColors[sum % communityColors.length];
};

export const CommunityIcon = (p: {
  isActive: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  id?: string;
  name?: string | null;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  style?: CSSProperties;
  abbreviate?: boolean;
  avatarUrl?: string | null;
  invited?: boolean;
  requested?: boolean;
  showSelector?: boolean;
  communities?: Community[];
  setCommunity?: (c: Community) => void;
}) => {
  const { _ } = useLingui();
  let abbr: string | null | undefined = null;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const linkRef = React.useRef<HTMLAnchorElement>(null);
  const handleClick = (event) => {
    setAnchorEl(linkRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!p.avatarUrl) {
    abbr = p.abbreviate !== false ? abbreviateCommunityName(p.name) : p.name;
  }
  const comp = (
    <LightTooltip
      arrow
      placement={p.tooltipPlacement}
      title={
        p.invited
          ? _(msg`You have been invited to join ${p.name}`)
          : p.requested
            ? _(msg`You have requested to join ${p.name}`)
            : p.name
      }
      {...(p.showSelector ? { open: false } : {})}
    >
      <a
        onClick={p.onClick}
        ref={linkRef}
        className={cc([
          {
            [C.active]: p.isActive,
            [C.item]: true,
            [C.blank]: !p.id,
          },
          p.className,
        ])}
        style={{
          ...p.style,
          backgroundImage: cssUrl(p.avatarUrl),
          backgroundColor: p.id ? getCommunityColor(p.id) : '#986CFC',
          opacity: p.invited ? '0.7' : '1',
        }}
      >
        <span>{abbr}</span>
        {p.showSelector && (
          <>
            <ExpandMoreIcon className={C.selector} onClick={handleClick} />
            <Menu
              PaperProps={{
                style: {
                  maxHeight: 200,
                  borderRadius: 10,
                  boxShadow: '0 0 5px 2px rgb(0 0 0 / 50%)',
                  transform: 'translateY(5px)',
                  // width: '20ch',
                },
              }}
              className={C.communitySelectorMenu}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {[
                p.communities?.find((c) => c.id === p.id),
                ...(p.communities ?? []).filter((c) => c.id !== p.id),
              ]?.map((c) => (
                <MenuItem
                  selected={c?.id === p.id}
                  onClick={() => {
                    if (!c) return;
                    p.setCommunity?.(c);
                    handleClose();
                  }}
                >
                  {c?.name}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </a>
    </LightTooltip>
  );

  if (p.invited || p.requested) {
    return (
      <div>
        <Badge color='warning' variant='dot'>
          {comp}
        </Badge>
      </div>
    );
  }

  return <div>{comp}</div>;
};

function abbreviateCommunityName(name: string | null | undefined) {
  if (!name) return '?';

  // Normalize the string to NFD form to handle accents properly
  return name
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // Remove diacritics (e.g., accents)
    .split(/\P{L}+/u) // Split by non-letter characters
    .map((it) => it.charAt(0).toUpperCase()) // Take the first letter of each part
    .join('')
    .slice(0, 3); // Return the first three letters
}

export const LightTooltip = styled(
  ({
    className,
    title,
    ...props
  }: Omit<TooltipProps, 'title'> & { title?: React.ReactNode | null }) => {
    if (!title) {
      return <>{props.children}</>;
    }
    return <Tooltip {...props} title={title} classes={{ popper: className }} />;
  },
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 0 2px 2px rgb(0 0 0 / 10%)',
    fontSize: 'var(--font-size-primary)',
    padding: '5px 10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    boxShadow: '0 0 2px 2px rgb(0 0 0 / 10%)',
  },
}));
