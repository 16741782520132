import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';


const MIXPANEL_PROD_TOKEN = '022e4d613c29441689ca813e457cb18d';
const MIXPANEL_DEV_TOKEN = 'e6c93646dbd165f77ab210e210f0c79d';

const MIXPANEL_TOKEN = window.location.hostname === 'coco.build' ? MIXPANEL_PROD_TOKEN : MIXPANEL_DEV_TOKEN;

mixpanel.init(MIXPANEL_TOKEN, {
    track_pageview: false, // we'll track these manually
    persistence: "localStorage",
    api_host: "https://data.coco.build",
    ignore_dnt: true,
    api_routes: {
        track: `1/`,
        engage: `2/`,
        groups: `3/`,
    },
    opt_out_tracking_by_default: navigator.webdriver,
});

export function identifyMixpanelUser(userId: string, name: string, email: string) {
    mixpanel.identify(userId);
    mixpanel.people.set({
        $name: name,
        $email: email,
    });
}

export function getMixpanel() {
    return mixpanel;
}
